import './index.css';

export default function Dashboard() {
    return (
        <div className='dashboard'>
            <img className='icone' src="/src/icone2.png" alt="" />

            <div className='content'>
                <div>
                    <span class="material-symbols-outlined">
                        attach_money
                    </span>

                    <span>R$100,00</span>
                </div>
                <div>
                    <span class="material-symbols-outlined">
                        arrow_upward
                    </span>

                    <span>R$150,00</span>
                </div>

                <div>
                    <span class="material-symbols-outlined">
                        arrow_downward
                    </span>

                    <span>R$50,00</span>
                </div>
            </div>

            <div className='table'>
                <div className='insert'>

                    <div className='text'>
                        <input type="text" placeholder='Descrição' />

                        <input type="text" placeholder='Valor' />
                    </div>

                    <div className='radio'>
                        <div>
                            <input name='radio' id='entrada' type="radio" />
                            <label for="entrada">Entrada</label>
                        </div>


                        <div>
                            <input name='radio' id='saida' type="radio" />
                            <label for="saida">Saida</label>
                        </div>
                    </div>

                    <button>Adicionar</button>

                </div>

                <table>
                    <tr key="">
                        <th>Descrição</th>
                        <th>Valor</th>
                        <th>Tipo</th>
                    </tr>

                    <tr key=""></tr>
                </table>
            </div>


        </div>
    );
}
import { Link } from 'react-router-dom';
import './App.css';

export default function App() {
  return (
    <div className="App">
      <img className='icone' src="/src/icone2.png" alt="" />


      <div class="container">
        <p>Vint</p>
        <ul>
          <li>Finance</li>
          <li>Innovation</li>
          <li>Solutions</li>
        </ul>
      </div>

      <div className='modal'>
        <Link to="/dashboard">
          <button>ENTRAR</button>
        </Link>
      </div>
    </div>

  );
}
import { Route, Routes, BrowserRouter } from "react-router-dom";
import App from "./App";
import Dashboard from "./components/Dashboard/index"

export default function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/dashboard" element={<Dashboard />} />
            </Routes>
        </BrowserRouter>
    )
}